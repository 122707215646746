


export const SERVER_BASE_URL = 'http://localhost:3001'
// export const SERVER_BASE_URL = 'https://siris-conformite.jcloud-ver-jpc.ik-server.com'
export const API_BASE_URL = SERVER_BASE_URL + '/api/'
export const BASE_URL = 'http://localhost:3000'
// export const BASE_URL = 'https://siris-conformite.jcloud-ver-jpc.ik-server.com'

export const SPACE = {
  'SUPER': 'Super',
  'CUSTOMER_ADMIN': 'Customer Admin',
  'CUSTOMER_Employee': 'Customer Employee',
}

export const ROLE_CLIENT = {
  'ADMINISTRATEUR': 'Administrateur',
  'MEMBRE_D_EQUIPE': 'Membre d\'équipe',
  'RESPONSABLE': 'Responsable'
}

export const PERMISSION_KEY = {
  read: 'permission_read',
  create: 'permission_create',
  update: 'permission_update',
  delete: 'permission_delete',
  admin: 'permission_admin'
}

export const PERMISSION_KEY_LABEL = {
  [PERMISSION_KEY.read]: 'constant.PERMISSION_KEY_LABEL.read',
  [PERMISSION_KEY.create]: 'constant.PERMISSION_KEY_LABEL.create',
  [PERMISSION_KEY.update]: 'constant.PERMISSION_KEY_LABEL.update',
  [PERMISSION_KEY.delete]: 'constant.PERMISSION_KEY_LABEL.delete',
  [PERMISSION_KEY.admin]: 'constant.PERMISSION_KEY_LABEL.admin'
}

export const PROPERTY_NAME_ACCESS = {
  REQUIREMENT: {
    title: 'constant.entity.requirements',
    read: 'requirement_permission_read',
    create: 'requirement_permission_create',
    update: 'requirement_permission_update',
    delete: 'requirement_permission_delete',
    admin: 'requirement_permission_admin',
    module: 'constant.module.compliance'
  },
  MY_REQUIREMENT: {
    title: 'constant.entity.my_requirements',
    read: 'my_requirement_permission_read',
    create: 'my_requirement_permission_create',
    update: 'my_requirement_permission_update',
    delete: 'my_requirement_permission_delete',
    admin: 'my_requirement_permission_admin',
    module: 'constant.module.compliance'
  },
  PROCESS: {
    title: 'constant.entity.processes',
    read: 'process_permission_read',
    create: 'process_permission_create',
    update: 'process_permission_update',
    delete: 'process_permission_delete',
    admin: 'process_permission_admin',
    module: 'constant.module.compliance'
  },
  PROCESS_EXIGENCE: {
    title: 'constant.entity.processes_and_requirements',
    read: 'process_exigence_permission_read',
    create: 'process_exigence_permission_create',
    update: 'process_exigence_permission_update',
    delete: 'process_exigence_permission_delete',
    admin: 'process_exigence_permission_admin',
    module: 'constant.module.compliance'
  },
  PERSONAL_INFO: {
    title: 'constant.entity.personal_information',
    read: 'personal_info_permission_read',
    create: 'personal_info_permission_create',
    update: 'personal_info_permission_update',
    delete: 'personal_info_permission_delete',
    admin: 'personal_info_permission_admin',
    module: 'constant.module.settings'
  },
  SUBSCRIPTION: {
    title: 'constant.entity.subscriptions',
    read: 'subscription_permission_read',
    create: 'subscription_permission_create',
    update: 'subscription_permission_update',
    delete: 'subscription_permission_delete',
    admin: 'subscription_permission_admin',
    module: 'constant.module.settings'
  },
  SETTINGS_INCIDENT_TYPE: {
    title: 'constant.entity.incident_types',
    read: 'settings_incident_type_permission_read',
    create: 'settings_incident_type_permission_create',
    update: 'settings_incident_type_permission_update',
    delete: 'settings_incident_type_permission_delete',
    admin: 'settings_incident_type_permission_admin',
    module: 'constant.module.settings'
  },
  SETTINGS_INFORMATION_SOURCE: {
    title: 'constant.entity.information_sources',
    read: 'settings_information_source_permission_read',
    create: 'settings_information_source_permission_create',
    update: 'settings_information_source_permission_update',
    delete: 'settings_information_source_permission_delete',
    admin: 'settings_information_source_permission_admin',
    module: 'constant.module.settings'
  },
  SETTINGS_LOCATION: {
    title: 'constant.entity.locations',
    read: 'settings_location_permission_read',
    create: 'settings_location_permission_create',
    update: 'settings_location_permission_update',
    delete: 'settings_location_permission_delete',
    admin: 'settings_location_permission_admin',
    module: 'constant.module.settings'
  },
  SETTINGS_EQUIPMENT: {
    title: 'constant.entity.equipments',
    read: 'settings_equipment_permission_read',
    create: 'settings_equipment_permission_create',
    update: 'settings_equipment_permission_update',
    delete: 'settings_equipment_permission_delete',
    admin: 'settings_equipment_permission_admin',
    module: 'constant.module.settings'
  },
  SETTINGS_ROLE: {
    title: 'constant.entity.roles',
    read: 'settings_role_client_permission_read',
    create: 'settings_role_client_permission_create',
    update: 'settings_role_client_permission_update',
    delete: 'settings_role_client_permission_delete',
    admin: 'settings_role_client_permission_admin',
    module: 'constant.module.settings'
  },
  SETTINGS_DEPARTMENT: {
    title: 'constant.entity.departments',
    read: 'settings_company_department_permission_read',
    create: 'settings_company_department_permission_create',
    update: 'settings_company_department_permission_update',
    delete: 'settings_company_department_permission_delete',
    admin: 'settings_company_department_permission_admin',
    module: 'constant.module.settings'
  },
  SETTINGS_CATEGORY_RISK: {
    title: 'constant.entity.risk_categories',
    read: 'settings_category_risk_permission_read',
    create: 'settings_category_risk_permission_create',
    update: 'settings_category_risk_permission_update',
    delete: 'settings_category_risk_permission_delete',
    admin: 'settings_category_risk_permission_admin',
    module: 'constant.module.settings'
  },
  SETTINGS_INCIDENT_PRIORITY: {
    title: 'constant.entity.incident_priorities',
    read: 'settings_incident_priority_permission_read',
    create: 'settings_incident_priority_permission_create',
    update: 'settings_incident_priority_permission_update',
    delete: 'settings_incident_priority_permission_delete',
    admin: 'settings_incident_priority_permission_admin',
    module: 'constant.module.settings'
  },
  SETTINGS_REFERENCE: {
    title: 'nav.settingsReference',
    read: 'settings_reference_permission_read',
    create: 'settings_reference_permission_create',
    update: 'settings_reference_permission_update',
    delete: 'settings_reference_permission_delete',
    admin: 'settings_reference_permission_admin',
    module: 'constant.module.settings'
  },
  COMPLIANCE_ACTION: {
    title: 'constant.entity.compliance_actions',
    read: 'compliance_action_permission_read',
    create: 'compliance_action_permission_create',
    update: 'compliance_action_permission_update',
    delete: 'compliance_action_permission_delete',
    admin: 'compliance_action_permission_admin',
    module: 'constant.module.compliance'
  },
  EVALUATION_COMPLIANCE_ACTION: {
    title: 'constant.entity.evaluation_actions',
    read: 'evaluation_compliance_action_permission_read',
    create: 'evaluation_compliance_action_permission_create',
    update: 'evaluation_compliance_action_permission_update',
    delete: 'evaluation_compliance_action_permission_delete',
    admin: 'evaluation_compliance_action_permission_admin',
    module: 'constant.module.compliance'
  },
  HISTORY: {
    title: 'constant.entity.histories',
    read: 'history_permission_read',
    create: 'history_permission_create',
    update: 'history_permission_update',
    delete: 'history_permission_delete',
    admin: 'history_permission_admin',
    module: 'constant.module.compliance'
  },
  RISK_MATRIX: {
    title: 'constant.entity.matrices',
    read: 'risk_matrix_permission_read',
    create: 'risk_matrix_permission_create',
    update: 'risk_matrix_permission_update',
    delete: 'risk_matrix_permission_delete',
    admin: 'risk_matrix_permission_admin',
    module: 'constant.module.risks'
  },
  RISK: {
    title: 'constant.entity.risks',
    read: 'risk_permission_read',
    create: 'risk_permission_create',
    update: 'risk_permission_update',
    delete: 'risk_permission_delete',
    admin: 'risk_permission_admin',
    module: 'constant.module.risks'
  },
  RISK_PROCESS: {
    title: 'constant.entity.processes',
    read: 'risk_process_permission_read',
    create: 'risk_process_permission_create',
    update: 'risk_process_permission_update',
    delete: 'risk_process_permission_delete',
    admin: 'risk_process_permission_admin',
    module: 'constant.module.risks'
  },
  RISK_PROCESS_RISK: {
    title: 'constant.entity.processes_and_risks',
    read: 'risk_process_risk_permission_read',
    create: 'risk_process_risk_permission_create',
    update: 'risk_process_risk_permission_update',
    delete: 'risk_process_risk_permission_delete',
    admin: 'risk_process_risk_permission_admin',
    module: 'constant.module.risks'
  },
  RISK_INCIDENT: {
    title: 'constant.entity.incidents',
    read: 'risk_incident_permission_read',
    create: 'risk_incident_permission_create',
    update: 'risk_incident_permission_update',
    delete: 'risk_incident_permission_delete',
    admin: 'risk_incident_permission_admin',
    module: 'constant.module.risks'
  },
  RISK_CONTROL: {
    title: 'constant.entity.controls',
    read: 'risk_control_permission_read',
    create: 'risk_control_permission_create',
    update: 'risk_control_permission_update',
    delete: 'risk_control_permission_delete',
    admin: 'risk_control_permission_admin',
    module: 'constant.module.risks'
  },
  RISK_EVALUATION: {
    title: 'constant.entity.evaluations',
    read: 'risk_evaluation_permission_read',
    create: 'risk_evaluation_permission_create',
    update: 'risk_evaluation_permission_update',
    delete: 'risk_evaluation_permission_delete',
    admin: 'risk_evaluation_permission_admin',
    module: 'constant.module.risks'
  },
  DOC_DOCUMENT: {
    title: 'constant.entity.documents',
    read: 'doc_document_permission_read',
    create: 'doc_document_permission_create',
    update: 'doc_document_permission_update',
    delete: 'doc_document_permission_delete',
    admin: 'doc_document_permission_admin',
    module: 'constant.module.process'
  },
  DOC_PROCESS: {
    title: 'constant.entity.processes',
    read: 'doc_process_permission_read',
    create: 'doc_process_permission_create',
    update: 'doc_process_permission_update',
    delete: 'doc_process_permission_delete',
    admin: 'doc_process_permission_admin',
    module: 'constant.module.process'
  },
  DOC_INDICATOR: {
    title: 'constant.entity.indicators',
    read: 'doc_indicator_permission_read',
    create: 'doc_indicator_permission_create',
    update: 'doc_indicator_permission_update',
    delete: 'doc_indicator_permission_delete',
    admin: 'doc_indicator_permission_admin',
    module: 'constant.module.process'
  },
  DOC_PROCESS_CARD: {
    title: 'constant.entity.process_cards',
    read: 'doc_process_card_permission_read',
    create: 'doc_process_card_permission_create',
    update: 'doc_process_card_permission_update',
    delete: 'doc_process_card_permission_delete',
    admin: 'doc_process_card_permission_admin',
    module: 'constant.module.process'
  },
  RISK_ACTION: {
    title: 'constant.entity.compliance_actions',
    read: 'risk_action_permission_read',
    create: 'risk_action_permission_create',
    update: 'risk_action_permission_update',
    delete: 'risk_action_permission_delete',
    admin: 'risk_action_permission_admin',
    module: 'constant.module.risks'
  },
  EVALUATION_RISK_ACTION: {
    title: 'constant.entity.evaluation_actions',
    read: 'evaluation_risk_action_permission_read',
    create: 'evaluation_risk_action_permission_create',
    update: 'evaluation_risk_action_permission_update',
    delete: 'evaluation_risk_action_permission_delete',
    admin: 'evaluation_risk_action_permission_admin',
    module: 'constant.module.risks'
  },
  HR_PERSON: {
    title: 'constant.entity.people',
    read: 'hr_person_permission_read',
    create: 'hr_person_permission_create',
    update: 'hr_person_permission_update',
    delete: 'hr_person_permission_delete',
    admin: 'hr_person_permission_admin',
    module: 'constant.module.hr'
  },
  HR_JOB_TITLE: {
    title: 'constant.entity.job_titles',
    read: 'hr_job_title_permission_read',
    create: 'hr_job_title_permission_create',
    update: 'hr_job_title_permission_update',
    delete: 'hr_job_title_permission_delete',
    admin: 'hr_job_title_permission_admin',
    module: 'constant.module.hr'
  },
  HR_SKILL: {
    title: 'constant.entity.skills',
    read: 'hr_skill_permission_read',
    create: 'hr_skill_permission_create',
    update: 'hr_skill_permission_update',
    delete: 'hr_skill_permission_delete',
    admin: 'hr_skill_permission_admin',
    module: 'constant.module.hr'
  },
  HR_TASK: {
    title: 'constant.entity.tasks',
    read: 'hr_task_permission_read',
    create: 'hr_task_permission_create',
    update: 'hr_task_permission_update',
    delete: 'hr_task_permission_delete',
    admin: 'hr_task_permission_admin',
    module: 'constant.module.hr'
  },
  HR_COMPLIANCE_BY_PERSON: {
    title: 'constant.entity.compliance_by_person',
    read: 'hr_compliance_by_person_permission_read',
    create: 'hr_compliance_by_person_permission_create',
    update: 'hr_compliance_by_person_permission_update',
    delete: 'hr_compliance_by_person_permission_delete',
    admin: 'hr_compliance_by_person_permission_admin',
    module: 'constant.module.hr'
  },
  HR_COMPLIANCE_BY_TEAM: {
    title: 'constant.entity.compliance_by_team',
    read: 'hr_compliance_by_team_permission_read',
    create: 'hr_compliance_by_team_permission_create',
    update: 'hr_compliance_by_team_permission_update',
    delete: 'hr_compliance_by_team_permission_delete',
    admin: 'hr_compliance_by_team_permission_admin',
    module: 'constant.module.hr'
  },
  HR_TYPE_INTERESTED_PARTY: {
    title: 'constant.entity.type_stakeholder',
    read: 'hr_type_interested_party_permission_read',
    create: 'hr_type_interested_party_permission_create',
    update: 'hr_type_interested_party_permission_update',
    delete: 'hr_type_interested_party_permission_delete',
    admin: 'hr_type_interested_party_permission_admin',
    module: 'constant.module.settings'
  },
  HR_INTERESTED_PARTY: {
    title: 'constant.entity.stakeholder',
    read: 'hr_interested_party_permission_read',
    create: 'hr_interested_party_permission_create',
    update: 'hr_interested_party_permission_update',
    delete: 'hr_interested_party_permission_delete',
    admin: 'hr_interested_party_permission_admin',
    module: 'constant.module.settings'
  },
  HR_LEARNING_METHOD: {
    title: 'constant.entity.learning_method',
    read: 'hr_learning_method_permission_read',
    create: 'hr_learning_method_permission_create',
    update: 'hr_learning_method_permission_update',
    delete: 'hr_learning_method_permission_delete',
    admin: 'hr_learning_method_permission_admin',
    module: 'constant.module.settings'
  },
  HR_FORMATION: {
    title: 'constant.entity.training',
    read: 'hr_formation_permission_read',
    create: 'hr_formation_permission_create',
    update: 'hr_formation_permission_update',
    delete: 'hr_formation_permission_delete',
    admin: 'hr_formation_permission_admin',
    module: 'constant.module.hr'
  },
  HR_SESSION: {
    title: 'constant.entity.session',
    read: 'hr_session_permission_read',
    create: 'hr_session_permission_create',
    update: 'hr_session_permission_update',
    delete: 'hr_session_permission_delete',
    admin: 'hr_session_permission_admin',
    module: 'constant.module.hr'
  },
  SETTINGS_CATEGORY_CONTROL: {
    title: 'constant.entity.control_categories',
    read: 'settings_category_control_permission_read',
    create: 'settings_category_control_permission_create',
    update: 'settings_category_control_permission_update',
    delete: 'settings_category_control_permission_delete',
    admin: 'settings_category_control_permission_admin',
    module: 'constant.module.settings'
  }

};

export const TABLE_NAME_BY_PROPERTY_NAME = {
  REQUIREMENT: {
    tableName: ''
  },
  MY_REQUIREMENT: {
    tableName: ''
  },
  PROCESS: {
    tableName: 'process'
  },
  PROCESS_EXIGENCE: {
    tableName: ''
  },
  PERSONAL_INFO: {
    tableName: ''
  },
  SUBSCRIPTION: {
    tableName: ''
  },
  SETTINGS_INCIDENT_TYPE: {
    tableName: ''
  },
  SETTINGS_INFORMATION_SOURCE: {
    tableName: ''
  },
  SETTINGS_LOCATION: {
    tableName: ''
  },
  SETTINGS_EQUIPMENT: {
    tableName: ''
  },
  SETTINGS_ROLE: {
    tableName: ''
  },
  SETTINGS_DEPARTMENT: {
    tableName: ''
  },
  SETTINGS_CATEGORYs_RISK: {
    tableName: ''
  },
  SETTINGS_CATEGORYs_CONTROL: {
    tableName: ''
  },
  COMPLIANCE_ACTION: {
    tableName: ''
  },
  EVALUATION_COMPLIANCE_ACTION: {
    tableName: ''
  },
  HISTORY: {
    tableName: ''
  },
  RISK_MATRIX: {
    tableName: ''
  },
  RISK: {
    tableName: 'risk'
  },
  RISK_PROCESS: {
    tableName: 'process'
  },
  RISK_PROCESS_RISK: {
    tableName: ''
  },
  RISK_INCIDENT: {
    tableName: 'incident'
  },
  RISK_CONTROL: {
    tableName: 'control'
  },
  RISK_EVALUATION: {
    tableName: ''
  },
  DOC_DOCUMENT: {
    tableName: 'doc_document'
  },
  DOC_PROCESS: {
    tableName: 'process'
  },
  DOC_INDICATOR: {
    tableName: ''
  },
  DOC_PROCESS_CARD: {
    tableName: ''
  },
  RISK_ACTION: {
    tableName: ''
  },
  EVALUATION_RISK_ACTION: {
    tableName: ''
  },
  HR_PERSON: {
    tableName: ''
  },
  HR_JOB_TITLE: {
    tableName: ''
  },
  HR_SKILL: {
    tableName: ''
  },
  HR_TASK: {
    tableName: ''
  },
  HR_COMPLIANCE_BY_PERSON: {
    tableName: ''
  },
  HR_COMPLIANCE_BY_TEAM: {
    tableName: ''
  },
  HR_FORMATION: {
    tableName: 'hr_formation'
  },
};

export const MENU_NO_CHECK_ACCESS_READ = [
  PROPERTY_NAME_ACCESS.PROCESS.read,
  PROPERTY_NAME_ACCESS.RISK.read,
  PROPERTY_NAME_ACCESS.RISK_PROCESS.read,
  PROPERTY_NAME_ACCESS.RISK_INCIDENT.read,
  PROPERTY_NAME_ACCESS.RISK_CONTROL.read,
  PROPERTY_NAME_ACCESS.DOC_DOCUMENT.read,
  PROPERTY_NAME_ACCESS.DOC_PROCESS.read,
]

export const STATUT_EXIGENCE = {
  'EN_VIGUEUR': 'En vigueur',
  'A_VENIR': 'A venir',
  'ABROGEE': 'Abrogé'
}

export const APPLICABILITY_VERSION = {
  'APPLICABLE': 'applicable',
  'NOTAPPLICABLE': 'notApplicable',
  'NOTDEFINED': 'notDefined'
}
export const CHECK_POINT_STATUS = {
  'CONFORME': 'compliant',
  'NON_CONFORME': 'notCompliant',
  'NON_APPLICABLE': 'notAppicable',
  'NOT_DEFINED': 'notDefined',
}

export const MODULES = {
  'COMPLIANCE': 'compliance',
  'RISK': 'risk',
  'DOCUMENT': 'document',
  'HUMAN_RESOURCES': 'human_resources'

}

export const DOC_FILE_TYPE = {
  'URL': 'url',
  'ATTACHMENT': 'attachment',
  'BPMN': 'bpmn'
}

export const PROCESS_TYPE = {
  top: 'Processus de direction',
  middle: 'Processus opérationnels',
  bottom: 'Processus de support'
}

export const TYPE_ACTION_DASHBOARD = {
  'compliance': 'Mise en conformité',
  'nextCompliance': 'Action suite mise en conformité',
  'EvaluationControlRisk': 'Contrôle',
  'nextEvaluationControlRisk': 'Action suite contrôle',
}

export const SETTINGS_REFERENCE = {
  'risk': {
    key: 'risk',
    fieldName: 'risk'
  },
  'control': {
    key: 'control',
    fieldName: 'control'
  },
  'incident': {
    key: 'incident',
    fieldName: 'incident'
  },
  'document': {
    key: 'document',
    fieldName: 'doc_document'
  },
  'indicator': {
    key: 'indicator',
    fieldName: 'indicator'
  },
  'process': {
    key: 'process',
    fieldName: 'process'
  },
  'session': {
    key: 'session',
    fieldName: 'hr_session'
  },
  'interested_party': {
    key: 'interested_party',
    fieldName: 'hr_interested_party'
  }
}

export const WORKFLOW_DOC_VALIDATION = {
  pending: { label: 'En attente', value: "pending" },
  started: { label: 'Démarrée', value: "started" },
  ended: { label: 'Terminé', value: "ended" },
}


export const WORKFLOW_DOC_VALIDATION_DECISION = {
  approved: { label: 'Approuvée', value: "approved" },
  refused: { label: 'Refusé', value: "refused" },
  inProgress: { label: 'En cours', value: "inProgress" },
}
